<template>
  <div class="orange-stores d-flex flex-column align-items-center">
    <h4 class="font-weight-bold wd-100p wd-sm-75p mt-3 d-none d-md-block">
      {{ t("partner.allstores") }}
    </h4>
    <div
      class="d-flex flex-column flex-md-row mn-wd-md-75p mx-wd-md-75p justify-content-center align-items-center align-items-md-start mb-3"
    >
      <div class="orange-stores__filter d-none d-md-block">
        <orange-stores-filter
          :industries="industries"
          @selected-industries-updated="updateSelectedIndustries"
          :partner="partner"
        />
      </div>
      <div class="orange-stores__filter d-md-none">
        <orange-stores-filter
          :industries="industries"
          :mobile="true"
          @selected-industries-updated="updateSelectedIndustries"
          :partner="partner"
        />
      </div>
      <h4 class="font-weight-bold wd-100p wd-sm-75p mt-3 text-center d-md-none">
        {{ t("partner.allstores") }}
      </h4>
      <div class="orange-stores__list">
        <orange-stores-list
          :stores="searchQueryStores"
          :industries="industries"
          :partner="partner"
          @search-stores="searchStores"
          @paginate-more-stores="loadNextPage"
          :loading="loading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import orangeStoresFilter from "./components/orange-stores-filter.vue";
import orangeStoresList from "./components/orange-stores-list.vue";
import i18n from "./mixins/i18n";

export default {
  name: "orangeStores",
  components: { orangeStoresFilter, orangeStoresList },
  mixins: [i18n],
  props: ["initialStores", "initialIndustries", "partner"],
  data: function () {
    return {
      searchQueryStores: this.initialStores,
      industries: this.initialIndustries,
      searchQuery: "",
      selectedIndustryIds: [],
      nextPage: 2,
      loading: false,
    };
  },
  methods: {
    updateSelectedIndustries(selectedIndustries) {
      this.selectedIndustryIds = selectedIndustries;
      // Call search endpoint without modifying current query
      this.searchStores(null);
    },
    searchStores: _.debounce(function (searchQuery) {
      // Store search query for usage in industry filtering if it was passed
      if (searchQuery || searchQuery === "") {
        this.searchQuery = searchQuery;
      }
      // Search for stores
      $.ajax({
        type: "POST",
        url: "/mall/search_stores/",
        data: {
          query: this.searchQuery,
          industries: this.selectedIndustryIds,
        },
        dataType: "json",
        timeout: 30000, // in milliseconds
        success: function (data) {
          return data;
        },
        error: function (request, err, status) {},
      }).then((data) => {
        // Update stores returned from search query
        this.searchQueryStores = data.companies;
        // Reset next page counter
        this.nextPage = 2;
      });
    }, 350),
    async loadNextPage() {
      // nextPage being -1 is a flag to let us know next page is empty hence stop trying
      if (this.nextPage !== -1 && !this.loading) {
        // Flag for loading animation and to disable multiple requests at same time
        this.loading = true;
        // POST Request for fetching more stores
        await $.ajax({
          type: "POST",
          url: "/mall/search_stores/",
          data: {
            query: this.searchQuery,
            industries: this.selectedIndustryIds,
            page: this.nextPage,
          },
          dataType: "json",
          timeout: 30000, // in milliseconds
          success: function (data) {
            return data;
          },
          error: function (request, err, status) {},
        }).then((data) => {
          if (data.companies.length === 0) {
            // If no companies returned on this page, disable more pagination
            this.nextPage = -1;
          } else {
            // Adds new stores to existing stores list
            this.searchQueryStores.push(...data.companies);
            // Increment next page number
            this.nextPage++;
          }
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.orange-stores {
  padding-top: 60px;
}
.orange-stores__filter {
  width: 100%;
  justify-content: center;
  display: flex;
}
.orange-stores__list {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .orange-stores {
    padding-top: 60px;
  }
  .orange-stores__filter {
    min-width: 25%;
    max-width: 25%;
  }
  .orange-stores__list {
    min-width: 75%;
    max-width: 75%;
  }
}
@media only screen and (max-width: 767px) {
  .orange-stores h4 {
    font-size: 16px;
  }
}
</style>
