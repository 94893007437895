<template>
  <div v-if="!mobile" class="orange-stores-filter mt-3 mr-3">
    <orangeStoresFilterChecklist
      :industries="industries"
      :mobile="false"
      @selected-industries-updated="updateIndustriesSelected"
      :partner="partner"
    />
  </div>
  <div v-else class="w-100 text-center">
    <button
      class="btn wd-250"
      v-bind:style="btnStyling"
      @click="showFilterDrawer"
      v-on:click.stop
    >
      {{ t("partner.filter_industries") }}
    </button>
    <div
      :class="[
        { checklistDrawerVisible: filterDrawerShown },
        'checklistDrawer',
      ]"
      :style="checklistDrawerColor"
    >
      <orangeStoresFilterChecklist
        :industries="industries"
        :mobile="true"
        @selected-industries-updated="updateIndustriesSelected"
        @apply-store-filters="applyFilters"
        v-click-outside="hideFilterDrawer"
        :partner="partner"
      />
    </div>
  </div>
</template>

<script>
import orangeStoresFilterChecklist from "./orange-stores-filter-checklist.vue";
import i18n from "../mixins/i18n";

export default {
  name: "orangeStoresFilter",
  components: { orangeStoresFilterChecklist },
  mixins: [i18n],
  props: ["industries", "mobile", "partner"],
  data: function () {
    return {
      selectedIndustryIds: [],
      selectAll: false,
      filterDrawerShown: false,
      btnStyling: {
        "background-color": this.partner.primary_color,
        color: this.partner.secondary_color,
      },
      checklistDrawerColor: {
        "background-color": this.partner.secondary_color,
      },
    };
  },
  methods: {
    updateIndustriesSelected(newSelectedIndustries) {
      this.selectedIndustryIds = newSelectedIndustries;
      this.$emit("selected-industries-updated", this.selectedIndustryIds);
    },
    showFilterDrawer() {
      this.filterDrawerShown = !this.filterDrawerShown;
    },
    applyFilters() {
      this.filterDrawerShown = false;
    },
    hideFilterDrawer(e) {
      this.filterDrawerShown = false;
    },
  },
  watch: {
    selectAll(newVal) {
      if (newVal) {
        this.selectedIndustryIds = this.industries.reduce(function (a, b) {
          a.push(b.id);
          return a;
        }, []);
      } else {
        this.selectedIndustryIds = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.orange-stores-filter {
  border: 1px solid #cccccc;
  height: 95%;
}

.checklistDrawer {
  position: fixed;
  z-index: 2;
  top: 75px;
  left: 0;
  height: 100%;
  width: 65%;
  border: 2px solid #ccc;
  margin-left: -65%;
  transition: 450ms ease;
}

.checklistDrawerVisible {
  margin-left: 0;
}
</style>
