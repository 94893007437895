<template>
  <div>
    <a target="_blank" :href="partner.redirect_url">
      <div class="w-100 mt-3 d-none d-sm-block">
        <img
          :src="partner.banner_store2_ar"
          class="w-100"
          v-if="currentLocale() === 'ar'"
        />
        <img :src="partner.banner_store2_en" class="w-100" v-else />
      </div>
    </a>
    <div class="d-flex w-100 justify-content-end my-4">
      <div class="mall-search-box py-1">
        <i class="mx-3 fas fa-search mall-search-box-icon"></i>
        <input
          class="hidden-input font-weight-bold w-100"
          placeholder="Type to search"
          v-model="searchQuery"
        />
      </div>
    </div>
    <div class="row justify-content-center mt-3 orange-stores-list">
      <div
        class="col-6 col-sm-4 col-xxl-3 d-flex pb-3"
        v-if="partner.code === 'orange'"
      >
        <div
          class="orange-store-card d-flex flex-column justify-content-between px-3"
        >
          <div>
            <a
              target="_blank"
              href="https://shop.orange.eg/en/"
              :draggable="false"
              class="w-100"
            >
              <img
                class="w-100"
                v-bind:src="require('images/logos/orange_logo2.png')"
              />
            </a>
            <div class="my-3 font-weight-bold orange-store-name">
              {{ t("orange.shop_name") }}
            </div>
          </div>
          <div class="orange-store-description">
            <div>
              {{ t("orange.shop_description") }}
            </div>
          </div>
          <div>
            <div class="w-100 d-flex justify-content-start mt-3 mb-5">
              <div class="font-italic">
                {{ t("orange.shop_industry") }}
              </div>
            </div>
            <div class="w-100 pb-3">
              <a
                target="_blank"
                href="https://shop.orange.eg/en/"
                class="btn-orange d-block font-weight-bold w-100 text-center font-weight-bold"
                style="font-weight: 1rem"
                >{{ t("shop_now") }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-6 col-sm-4 col-xxl-3 d-flex pb-3"
        v-for="(store, index) in stores"
        :key="index"
      >
        <div
          class="orange-store-card d-flex flex-column justify-content-between px-3"
        >
          <div>
            <a
              target="_blank"
              :href="`${store.store_url}`"
              :key="store.id"
              :draggable="false"
              :id="store.name"
              class="w-100"
            >
              <img
                class="w-100"
                :src="store.cover_url"
                :alt="store.name"
                v-if="store.cover_url"
              />
              <img
                class="w-100"
                v-else
                v-bind:src="require('images/your_logo_here.png')"
              />
            </a>
            <div class="my-3 font-weight-bold orange-store-name">
              {{ store.name }}
            </div>
          </div>
          <div class="orange-store-description">
            <div>
              {{ store.mall_description }}
            </div>
          </div>
          <div>
            <div class="w-100 d-flex justify-content-start mt-3 mb-5">
              <div class="font-italic">
                {{ getStoreIndustryNames(store.industry_path_names) }}
              </div>
            </div>
            <div class="w-100 pb-3">
              <a
                target="_blank"
                :href="`${store.store_url}`"
                class="btn-orange d-block font-weight-bold w-100 text-center font-weight-bold"
                style="font-weight: 1rem"
                >{{ t("shop_now") }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-100 d-flex justify-content-center"
        v-if="stores.length !== 0 && loading"
      >
        <img
          id="btn-apply-products-loading"
          class="mx-2"
          v-bind:src="require('images/loading-black.gif')"
        />
      </div>

      <h4 class="empty-message mt-5" v-if="stores.length === 0">
        {{ t("partner.no_stores") }}
      </h4>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import i18n from "../mixins/i18n";

export default {
  name: "orangeStoresList",
  components: {},
  mixins: [i18n],
  props: ["partner", "stores", "industries", "loading"],
  data: function () {
    return {
      searchQuery: "",
    };
  },
  computed: {
    bannerUrl: function () {
      return `/users/sign_up?&partner=${this.partner.name.toLowerCase()}`;
    },
  },
  watch: {
    searchQuery(newVal) {
      this.$emit("search-stores", newVal);
    },
  },

  methods: {
    getStoreIndustryNames(industryPathNames) {
      var resultString = "";
      if (industryPathNames) {
        industryPathNames.forEach((name, index) => {
          if (index < industryPathNames.length - 1)
            resultString += name + " • ";
          else resultString += name;
        });
      }
      return resultString;
    },
    loadNextPage() {
      this.$emit("paginate-more-stores", this.searchQuery);
    },
    handleScroll({
      target: {
        scrollingElement: { scrollTop, clientHeight, scrollHeight },
      },
    }) {
      // If scrolled to navbar (page height - 250), load next
      if (scrollTop + clientHeight >= scrollHeight - 250 && !this.loading) {
        this.loadNextPage();
      }
    },
  },
  created: function () {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed: function () {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.orange-stores-list {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
@media (min-width: 992px) {
  .orange-stores-list {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
}
.orange-store-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.orange-store-card {
  box-shadow: 0px 3px 6px #00000029;
}
.orange-store-description {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.hidden-input {
  border: none;
  background-color: transparent;
}

.mall-search-box-icon {
  font-size: 1.5rem;
}
</style>
