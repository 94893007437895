<template>
  <div class="mt-4 orange-stores__filter-checklist text-left">
    <!-- Select All -->
    <div class="custom-control custom-checkbox m-2 ml-4 ml-sm-2">
      <input
        type="checkbox"
        :id="'selectAll' + mobile.toString()"
        :value="true"
        v-model="selectAll"
        class="custom-control-input cursor-pointer"
      />
      <label
        :class="[
          selectAll ? 'font-weight-bold' : 'font-weight-normal',
          'custom-control-label',
          'cursor-pointer',
        ]"
        :for="'selectAll' + mobile.toString()"
      >
        {{ t("partner.allstores") }}
      </label>
    </div>
    <!-- Industry Options -->
    <div
      v-for="industry in sortedIndustries"
      :key="industry.id"
      class="custom-control custom-checkbox m-2 ml-4 ml-sm-2"
    >
      <input
        type="checkbox"
        :id="industry.id + mobile.toString()"
        :value="industry.id"
        v-model="selectedIndustryIds"
        class="custom-control-input cursor-pointer"
      />
      <label
        :class="[
          selectedIndustryIds.includes(industry.id)
            ? 'font-weight-bold'
            : 'font-weight-normal',
          'custom-control-label',
          'cursor-pointer',
        ]"
        :for="industry.id + mobile.toString()"
        >{{ industry.name }}</label
      >
      <div v-for="child in industry.children" :key="child.id" class="ml-3 mt-1">
        <input
          type="checkbox"
          :id="child.id + mobile.toString()"
          :value="child.id"
          v-model="selectedIndustryIds"
          class="custom-control-input cursor-pointer"
        />
        <label
          :class="[
            selectedIndustryIds.includes(child.id)
              ? 'font-weight-bold'
              : 'font-weight-normal',
            'custom-control-label',
            'cursor-pointer',
          ]"
          :for="child.id + mobile.toString()"
          >{{ child.name }}</label
        >
      </div>
    </div>
    <div class="mx-3 mt-5 mb-3">
      <button class="btn w-100" @click="applyFilters" :style="btnStyling">
        {{ t("search") }}
      </button>
    </div>
  </div>
</template>

<script>
import i18n from "../mixins/i18n";

export default {
  name: "orangeStoresFilterChecklist",
  components: {},
  mixins: [i18n],
  props: ["industries", "mobile", "partner"],
  data: function () {
    return {
      selectedIndustryIds: [],
      selectAll: false,
      btnStyling: {
        "background-color": this.partner.primary_color,
        color: this.partner.secondary_color,
      },
    };
  },
  methods: {
    applyFilters() {
      this.$emit("apply-store-filters");
    },
  },
  computed: {
    sortedIndustries: function () {
      let finalArray = [...this.industries];
      let bouquetIndex = [...this.industries].findIndex((industry) => {
        switch (this.currentLocale()) {
          case "en":
            return industry.name.toLowerCase() === "bouquet";
          case "ar":
            return industry.name.toLowerCase() === "مختارات";
        }
        return industry.name.toLowerCase() === "bouquet";
      });
      if (bouquetIndex !== -1) {
        let boquetObject = [...this.industries][bouquetIndex];
        //Get Array without Boquet Object
        finalArray.splice(bouquetIndex, 1);
        // Sort Alphabetically
        finalArray.sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        // Add Boquet Object to start of array
        finalArray.unshift(boquetObject);
      } else {
        finalArray.sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
      }

      return finalArray;
    },
  },
  watch: {
    selectAll(newVal) {
      if (newVal) {
        this.selectedIndustryIds = this.industries
          .map((rootIndustry) => {
            var ids = [rootIndustry.id];
            if (rootIndustry.children) {
              ids.push(
                rootIndustry.children.map((childIndustry) => childIndustry.id)
              );
            }
            return ids;
          })
          .flat(2);
      } else {
        this.selectedIndustryIds = [];
      }
    },
    selectedIndustryIds: {
      deep: true,
      handler(newVal, oldVal) {
        var removedElements = oldVal.filter((e) => !newVal.includes(e));
        var addedElements = newVal.filter((e) => !oldVal.includes(e));

        // Remove children of removed elements
        removedElements.forEach((removedElementId) => {
          var industry = this.industries.find((x) => x.id === removedElementId);
          if (industry && industry.children) {
            industry.children.forEach((child) => {
              if (this.selectedIndustryIds.includes(child.id)) {
                this.selectedIndustryIds.splice(
                  this.selectedIndustryIds.findIndex((x) => x === child.id),
                  1
                );
              }
            });
          }
        });

        // Add children of added elements
        addedElements.forEach((addedElementId) => {
          var industry = this.industries.find((x) => x.id === addedElementId);
          if (industry && industry.children) {
            industry.children.forEach((child) => {
              if (!this.selectedIndustryIds.includes(child.id)) {
                this.selectedIndustryIds.push(child.id);
              }
            });
          }
        });

        this.$emit("selected-industries-updated", this.selectedIndustryIds);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.orange-stores__filter-checklist {
  max-height: calc(100vh - 150px) !important;
  overflow: scroll;
}
</style>
