import Vue from "vue/dist/vue.esm";
import orangeStores from "../../views/orange-stores.vue";
import clickOutside from "../directives/click-outside";

const element = document.getElementById("partner-stores");
const props = JSON.parse(element.getAttribute("props"));
Vue.directive("click-outside", clickOutside);

if (element != null) {
  new Vue({
    render: (h) => h(orangeStores, { props }),
  }).$mount(element);
}
